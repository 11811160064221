<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head back="Convention.Volunteers.Roles.Edit" :backParams="$route.meta.backParams" title="Volunteers" subtitle="Manage the volunteers assigned to this role." :readonly="$authorised('con/volunteers/access', 'read')">

		<app-content-head-actions-item text="Add" icon="add" v-on:click="onCreateClick" />
		<app-content-head-actions-item text="Remove" icon="removeCircle" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />
		<app-content-filters-checklist v-model="filter.days" text="Day" tooltip="Select days" :options="references.days" v-if="references.role.type === $constants.volunteers.role.type.schedule" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no volunteers found.">

		<app-table>

			<app-table-head :layout="layout[layoutType]">

				<app-table-column text="Reference" />
				<app-table-column text="Name" />
				<app-table-column text="Date" />
				<app-table-column text="Slot" v-if="references.role.type === $constants.volunteers.role.type.schedule" />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout[layoutType]" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.badge.reference" />
					<app-table-cell-text :text="item.badge.name" />
					<app-table-cell-text :text="item.date | localDate('MM/DD/YYYY h:mma')" />
					<app-table-cell-text :text="item.slot.name" v-if="references.role.type === $constants.volunteers.role.type.schedule" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			references: {
				days: [],
				role: false
			},
			filter: {
				days: []
			},
			layout: {
				schedule: '120px auto 240px 240px 24px',
				rollcall: '120px auto 240px 24px'
			},
			endpoint: 'convention/volunteers/roles/:role/assignments',
			live: 'convention/volunteers/roles/:role/assignments'
		}

	},

	computed: {

		layoutType: function() {

			return this.references.role.type === this.$constants.volunteers.role.type.schedule ? 'schedule' : 'rollcall'

		}

	}

}

</script>

<style scoped>

</style>